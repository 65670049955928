
import { checkPermission } from '@/api/utils';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  components: {},
  data() {
    return {
      optionsList: [
        { label: 'Paid', value: 'paid' },
        { label: 'Free', value: 'free' }
      ],
      optionSelected: [],
      tabIndex: 2,
      splitterModel: 20,
      vendors: ['HikVision', 'NCR', 'FILR'],
      tags: ['People Counting', 'POS', 'WIFI']
    };
  },
  computed: {
    ...mapState('marketplace', ['selectedTag', 'pricing']),
    ...mapGetters('user', ['getLocale'])
  },
  watch: {
    async optionSelected() {
      await this.setPricing([...this.optionSelected]);
    }
  },
  created() {
    // console.log(checkPermission('abc'));
  },
  methods: {
    ...mapMutations('marketplace', ['setSelectedTag', 'setPricing']),
    openFlowGoLink() {
      window.open(
        this.getLocale === 'ja'
          ? 'https://www.flow-solutions.com/mobile/information'
          : 'https://www.flow-solutions.com/en/information',
        '_blank'
      );
    }
  }
};
